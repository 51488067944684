import {
  H3,
  H5,
  RadioButton,
  Input,
  Button,
  InformationV2,
} from '@everlywell/leaves';
import apiUniqueIdValidator from 'api/unique-id-validator';
import { PageInfo } from 'components/eligibility-file/common';
import { Divider, Checkbox, useWizard } from 'components/ui';
import React, { useState } from 'react';
import { useForm, FormProvider, Controller } from 'react-hook-form';
import {
  ERROR_TITLE,
  ERROR_CONTENT,
  TC_LINK,
  CONSENT_AND_NOTICE_LINK,
  PRIVACY_POLICY_LINK,
} from 'utils/constants';
import {
  analytics,
  displayError,
  isValidDate,
  normalizeDate,
  formatDateOfBirth,
  isOfAge,
} from 'utils/helpers';
import { ChildProps } from 'components/ui/Wizard';

import * as S from '../styles';
import MemberIDModal from './MemberIDModal';

const getFormLabels = ({ caregiver_label, member_label, member_id_label }: ConfigValues) => {
  const customMemberId = member_id_label === '' ? 'member ID' : member_id_label;
  const customCaregiverLabel =
    caregiver_label === '' ? 'Health plan caregiver' : caregiver_label;
  const customMemberLabel =
    member_label === '' ? 'Health plan member' : member_label;

  return {
    caregiverLabel: customCaregiverLabel,
    memberLabel: customMemberLabel,
    caregiver: {
      heading: 'Enter the participant’s ZIP code and last name',
      memberID: `Participant’s ${customMemberId}`,
      dob: 'Participant’s date of birth',
      zipCode: 'Participant’s ZIP code',
      lastName: 'Participant’s last name',
    },
    member: {
      heading: 'Enter your ZIP code and last name',
      memberID: `Your <span data-isolate>${customMemberId}</span>`,
      dob: 'Your date of birth',
      zipCode: 'ZIP code',
      lastName: 'Last name',
    },
  };
};

const VerifyInformation = ({ navigation, isCurrentStep }: ChildProps) => {
  const { goToNextStep } = navigation;
  const { state, dispatch }: { state: OrderWizardState, dispatch: any } = useWizard();
  const [isLoading, setIsLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const { configValues } = state;
  const formLabels = getFormLabels(configValues);

  const methods = useForm({
    mode: 'all',
    defaultValues: {
      ...state?.form?.verifyInformation,
    },
  });

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    errors,
    formState: { isValid },
  } = methods;

  const validateUniqueId = async (member: OrderWizardState['form']['verifyInformation']) => {
    try {
      const response = await apiUniqueIdValidator(
        {
          member_id: member.id,
          dob: formatDateOfBirth(member.dob),
          last_name: member.lastName,
          zip_code: member.zipcode,
        },
        configValues.tenant_name,
      );

      return response.data;
    } catch (error) {
      return { statusCode: '500', error };
    }
  };

  const onSubmit = async (formData: OrderWizardState['form']['verifyInformation']) => {
    setIsLoading(true);

    const response = await validateUniqueId(formData);
    const eventData = {
      user_type: formData.kind,
    };

    setIsLoading(false);

    if (response.statusCode !== '200') {
      analytics.track('CTA Clicked', {
        ...eventData,
        error_type: ERROR_TITLE.INVALID_UNIQUE_ID,
        text: ERROR_CONTENT.INVALID_UNIQUE_ID.replace(/(<([^>]+)>)/gi, ''),
      });

      return displayError(
        ERROR_TITLE.INVALID_UNIQUE_ID,
        ERROR_CONTENT.INVALID_UNIQUE_ID,
      );
    }

    dispatch({
      type: 'updateData',
      data: {
        member: response?.data,
        form: {
          ...state?.form,
          verifyInformation: formData,
          shippingInformation: {}, // reset shipping information
        },
      },
    });
    analytics.track('CTA Clicked', {
      ...eventData,
      text: 'Verified Member Information Successfully',
    });

    goToNextStep();
  };

  const handleDOBChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const normalizedDate = normalizeDate(event.target.value);

    setValue('dob', normalizedDate);
  };

  const handleHelpIconKeydown = (event: KeyboardEvent) => {
    event.preventDefault();

    if (event.code === 'Enter') {
      setOpenModal(true);
    }
  };

  const watchedKind: OrderWizardState['form']['verifyInformation']['kind'] = watch('kind');

  return (
    <FormProvider {...methods}>
      <S.Form name="verify-information" onSubmit={handleSubmit(onSubmit)}>
        {isCurrentStep && (
          <PageInfo
            title="Verify Member Information"
            pageName="order - verify member information"
          />
        )}

        <S.FormHeading>
          <H3 as="h1">Verify Member Information</H3>
          <p>
            In order to claim your test, process your sample, and provide you
            with accurate results, we need to confirm your information.
          </p>
        </S.FormHeading>

        <Divider />

        <S.FormControls as="fieldset">
          <S.InputLabel as="legend">I am a...</S.InputLabel>
          <MemberIDModal
            open={openModal}
            openHandler={setOpenModal}
            uniqueIdLabel={configValues?.member_id_label}
            customContent={configValues?.tooltip_text ?? ''}
            customImage={configValues?.tooltip_image ?? ''}
          />
          <S.RadioGroup>
            <RadioButton
              id="member_kind"
              name="kind"
              label={formLabels['memberLabel']}
              value="member"
              border={true}
              ref={register({ required: true })}
            />
            <RadioButton
              id="member_caregiver"
              name="kind"
              label={formLabels['caregiverLabel']}
              value="caregiver"
              border={true}
              ref={register({ required: true })}
            />
          </S.RadioGroup>
        </S.FormControls>

        <S.FormControls>
          <S.InputLabelContainer>
            <S.InputLabel
              htmlFor="member_id"
              dangerouslySetInnerHTML={{
                __html: formLabels[watchedKind].memberID,
              }}
            />
            <InformationV2
              tabIndex="0"
              onClick={() => setOpenModal(true)}
              onKeyDown={handleHelpIconKeydown}
              aria-label="Need help ?"
              role="button"
              style={{ cursor: 'pointer' }}
            />
          </S.InputLabelContainer>
          <Input
            id="member_id"
            name="id"
            type="text"
            placeholder="ABC12345"
            error={errors?.id?.message}
            ref={register({ required: 'Please enter a valid ID' })}
            data-notranslate
          />
        </S.FormControls>

        <S.FormControls>
          <Input
            id="member_dob"
            name="dob"
            type="text"
            label={formLabels[watchedKind].dob}
            placeholder="MM/DD/YYYY"
            ref={register({
              required: 'Please enter a valid date',
              validate: (value) => {
                if(!isValidDate(value)) return 'Please enter a valid date'
                if (!isOfAge(value)) return "Must be 18+ to order this test."
              }
            })}
            onChange={handleDOBChange}
            error={errors?.dob?.message}
          />
        </S.FormControls>

        <Divider />

        <H5 as="h2">{formLabels[watchedKind].heading}</H5>
        <Divider isHidden spacing="8px" />
        <S.FormControls>
          <Input
            id="member_zipcode"
            name="zipcode"
            type="text"
            label={formLabels[watchedKind].zipCode}
            placeholder="00000"
            maxLength={5}
            ref={register({
              required: 'Please enter a valid zipcode',
              pattern: {
                value: /^[0-9]{5}(?:-[0-9]{4})?$/,
                message: 'Please enter a valid zipcode',
              },
            })}
            error={errors?.zipcode?.message}
            data-notranslate
          />
        </S.FormControls>
        <S.FormControls>
          <Input
            id="member_lastName"
            name="lastName"
            type="text"
            label={formLabels[watchedKind].lastName}
            placeholder="Smith"
            ref={register({ required: 'Please enter a valid last name' })}
            error={errors?.lastName?.message}
          />
        </S.FormControls>

        <Divider spacing="24px" isHidden />

        <S.FormControls>
          <Controller
            name="consent"
            rules={{ required: true }}
            render={({ onChange, name, value, ref }) => (
              <Checkbox
                // @ts-ignore
                onChange={(e) => onChange(e.target.checked)}
                name={name}
                value={value}
                inputRef={ref}
                data-testid="consent-checkbox"
              >
                I have read and accept the{' '}
                <strong>
                  <S.Link href={CONSENT_AND_NOTICE_LINK}>
                    Informed Consent and Notice of Privacy Practices
                  </S.Link>
                </strong>
              </Checkbox>
            )}
          />
        </S.FormControls>

        <S.FormControls>
          <Controller
            name="userAgreement"
            rules={{ required: true }}
            render={({ onChange, name, value, ref }) => (
              <Checkbox
                // @ts-ignore
                onChange={(e) => onChange(e.target.checked)}
                name={name}
                value={value}
                inputRef={ref}
                data-testid="userAgreement-checkbox"
              >
                I have read and accept the{' '}
                <strong>
                  <S.Link href={TC_LINK}>User Agreement</S.Link>
                </strong>{' '}
                and{' '}
                <strong>
                  <S.Link href={PRIVACY_POLICY_LINK}>Privacy Policy</S.Link>
                </strong>
              </Checkbox>
            )}
          />
        </S.FormControls>

        <S.ButtonGroup>
          <Button isLoading={isLoading} isDisabled={!isValid}>
            Continue
          </Button>
        </S.ButtonGroup>
      </S.Form>
    </FormProvider>
  );
};

export default VerifyInformation;
